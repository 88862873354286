<template>
  <div class="related-products__product">
    <div class="related-products__product--image">
      <div class="image" :style="{ backgroundImage: 'url(/images/custom-product.jpg)' }"></div>
    </div>
    <ul class="related-products__product--props">
      <li>{{ description }}</li>
      <li>Nom du fournisseur: {{ name }}</li>
      <li>Marque: {{ mark }}</li>
      <li>Référence: {{ reference }}</li>
      <li>Prix du produit: {{ customPrice }}</li>
    </ul>
    <div v-if="loader" class="loader-wrapper">
      <div class="loader">
        <v-progress-circular
          :size="40"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomProductInfo",
  props: {
    loader: {
      type: Boolean,
    },
    name: {
      type: String,
      required: true,
    },
    mark: {
      type: String,
      required: true,
    },
    reference: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    customPrice: {
      type: String,
      required: true
    },
  },
};
</script>

<style>
</style>